<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>联系我们</h2>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>联系我们</h4>
        </div>
      </div>

      <div class="pc-customeValue container d-none d-lg-block">
        <div class="main-cot">
          <div>
            <img src="@/assets/newSite/aboutus/map.png" alt="" />
          </div>
          <div>
            <div>
              <h2>公司地址</h2>
              <div>深圳市南山区朗山路16号华瀚创新园A座9楼</div>
            </div>
            <div style="margin: 30px 0">
              <h2>服务热线</h2>
              <div>13808805396</div>
            </div>
            <div>
              <h2>邮箱</h2>
              <div>cs@veiban.com</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-customeValue d-block d-lg-none">
        <div class="container">
          <div class="main-cot">
            <div style="margin-bottom: 30px">
              <img src="@/assets/newSite/aboutus/map.png" alt="" />
            </div>
            <div>
              <div>
                <h3>公司地址</h3>
                <div>深圳市南山区朗山路16号华瀚创新园A座9楼</div>
              </div>
              <div style="margin: 30px 0">
                <h3>服务热线</h3>
                <div>13808805396</div>
              </div>
              <div>
                <h3>邮箱</h3>
                <div>cs@veiban.com</div>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <NewFooter  />
      <MobileFooter class="d-block d-lg-none" />
    </div>
  </div>
</template>
            
      <script>
import { Newslist } from "@/api/fetch";
import Qs from "qs";
export default {
  name: "hardware",
  data() {
    return {
      cur: 1,
      activeName: "1",
    };
  },
  created() {},
  methods: {},
};
</script>
            
            <style lang="less"  scoped>
.apipe-cloud-course-container {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/aboutus/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p4.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-customeValue {
    padding: 20px;
    .main-cot {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      img {
        width: 800px;
        height: 460px;
      }
    }
  }
  .mobile-customeValue {
      .main-cot {
        padding: 20px;
      text-align: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
            